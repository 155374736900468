<script lang="ts" setup>
import type { FormKitComponent } from '@formkit/vue'
import type { ILeaseFieldGroup } from '@register'
import { sampleNamingConventionOutput } from './utils/sampleOutput'

type ReferenceName = {
  variableOne?: string
  variableTwo?: string
  variableThree?: string
  separator: {
    id: string
    label: string
  }
}

interface Props {
  referenceName?: ReferenceName
  separators: { id: string; label: string }[]
  isLoading: boolean
  fields?: ILeaseFieldGroup[]
}

const props = withDefaults(defineProps<Props>(), {
  referenceName: () => ({
    variableOne: undefined,
    variableTwo: undefined,
    variableThree: undefined,
    separator: {
      id: '',
      label: '',
    },
  }),
  isLoading: false,
  separators: () => [],
  fields: () => [],
})

// Variables
const model = ref(initializeModel())
const form = ref<FormKitComponent & { node: any }>(null!)

const referenceExample = computed(() => {
  const isEmbedded = model.value.separator.id?.length > 1

  const varOne = model.value.variableOne ?? 'Variable 1'
  const varTwo =
    model.value.variableTwo === 'NONE' ? '' : (model.value.variableTwo ?? '')
  const varThree =
    model.value.variableThree === 'NONE'
      ? ''
      : (model.value.variableThree ?? '')

  const variables = [varOne, varTwo, varThree].filter(Boolean)

  if (isEmbedded) {
    const start = model.value?.separator.id.charAt(0)
    const end = model.value?.separator.id.slice(-1)
    const preview = variables
      .map(
        (v) => `${start}${sampleNamingConventionOutput[v] ?? title(v)}${end}`,
      )
      .join(' ')

    const body = variables.map((v) => `${start}{${v}}${end}`).join(' ')
    return { preview, body }
  }
  const separator = model.value?.separator?.id ?? ' '
  const preview = variables
    .map((v) => sampleNamingConventionOutput[v] ?? title(v))
    .join(` ${separator} `)
  const body = variables.map((v) => `{${v}}`).join(` ${separator} `)
  return { preview, body }
})

const emit = defineEmits<{
  (e: 'submit-upload-reference', data: string): void
}>()

// Methods
function initializeModel() {
  return {
    variableOne: props.referenceName.variableOne,
    variableTwo: props.referenceName.variableTwo,
    variableThree: props.referenceName.variableThree,
    separator: props.referenceName.separator,
  }
}

function onSubmit() {
  emit('submit-upload-reference', referenceExample.value.body)
}

// Watchers
watch(
  () => props.referenceName,
  () => {
    model.value = initializeModel()
  },
)

const { currentDivision } = useDivisions()

const options1 = computed(() => {
  return (
    props.fields?.filter(
      (field) =>
        field.id !== model.value.variableTwo &&
        field.id !== model.value.variableThree,
    ) ?? []
  )
})

const options2 = computed(() => {
  return [
    { id: 'NONE', name: 'NONE' },
    ...(props.fields?.filter(
      (field) =>
        field.id !== model.value.variableOne &&
        field.id !== model.value.variableThree,
    ) ?? []),
  ]
})

const options3 = computed(() => {
  return [
    { id: 'NONE', name: 'NONE' },
    ...(props.fields?.filter(
      (field) =>
        field.id !== model.value.variableOne &&
        field.id !== model.value.variableTwo,
    ) ?? []),
  ]
})
</script>

<template>
  <div class="flex w-full max-w-7xl flex-col gap-4">
    <section class="">
      <h2 class="py-2">
        Naming convention
        <template v-if="currentDivision?.name">
          <Tooltip
            content="Only applies for this division"
            class="z-[10000] bg-gray-950 text-xs"
            outer-class="cursor-help"
            placement="top"
          >
            for
            <span class="text-primary font-bold underline decoration-dotted">
              {{ currentDivision.name }}
            </span>
          </Tooltip>
        </template>
      </h2>
      <p>The quickest way to locate all documents.</p>
      <p
        v-if="currentDivision?.name"
        class="border-warning text-warning bg-warning/5 mt-4 inline-flex shrink items-center gap-2 rounded-md border p-4"
      >
        <Icon name="warning" class="h-4 w-4" />
        <span>
          By changing the naming convention of
          <span class="inline font-bold">{{ currentDivision.name }}</span
          >, it will not affect any other division
        </span>
      </p>

      <FormKit
        ref="form"
        v-model="model"
        type="form"
        :actions="false"
        outer-class="w-full"
        form-class="flex flex-col w-full"
        :disabled="form?.node.context.state.loading"
        dirty-behavior="compare"
        :loading="isLoading"
        @submit="onSubmit"
      >
        <div class="mt-8 w-full pl-3 md:w-1/4">
          <FormKit
            id="separatorField"
            outer-class="w-full"
            type="listbox"
            format="option"
            name="separator"
            track-by="id"
            label-by="label"
            label="Select a separator"
            :value="model.separator"
            :options="separators"
            validation="required"
            :searchable="true"
          />
        </div>

        <div
          class="mt-8 grid max-w-7xl grid-cols-1 md:grid-cols-3"
          :class="[model.separator?.id?.length > 1 ? 'gap-8' : 'gap-3']"
        >
          <div
            class="flex justify-center"
            :class="[model.separator?.id?.length > 1 ? 'gap-1' : 'gap-3']"
          >
            <div class="mt-5 self-center text-2xl font-bold">
              <template v-if="model.separator?.id?.length > 1">
                {{ model.separator?.id?.charAt(0) }}
              </template>
            </div>
            <FormKit
              outer-class="w-full"
              type="listbox"
              name="variableOne"
              track-by="id"
              label-by="name"
              label="First variable"
              :options="options1"
              :searchable="true"
              validation="required"
            />
            <div class="mt-5 self-center text-2xl font-bold">
              {{ model.separator?.id?.slice(-1) }}
            </div>
          </div>

          <div
            class="flex justify-center"
            :class="[model.separator?.id?.length > 1 ? 'gap-1' : 'gap-3']"
          >
            <div
              v-if="
                model.separator?.id?.length > 1 && model.variableTwo !== 'NONE'
              "
              class="mt-5 self-center text-2xl font-bold"
            >
              {{ model.separator?.id?.charAt(0) }}
            </div>
            <FormKit
              outer-class="w-full"
              type="listbox"
              name="variableTwo"
              track-by="id"
              label-by="name"
              label="Second variable"
              :options="options2"
              :searchable="true"
            />
            <div
              v-if="model.variableTwo !== 'NONE'"
              class="mt-5 self-center text-2xl font-bold"
            >
              {{ model.separator?.id?.slice(-1) }}
            </div>
          </div>

          <div
            class="flex justify-center"
            :class="[model.separator?.id?.length > 1 ? 'gap-1' : 'gap-3']"
          >
            <div
              v-if="
                model.separator?.id?.length > 1 &&
                model.variableTwo !== 'NONE' &&
                model.variableThree !== 'NONE'
              "
              class="mt-5 self-center text-2xl font-bold"
            >
              {{ model.separator?.id?.charAt(0) }}
            </div>

            <FormKit
              v-if="model.variableTwo !== 'NONE'"
              outer-class="w-full"
              type="listbox"
              name="variableThree"
              track-by="id"
              label-by="name"
              label="Third variable"
              :options="options3"
              :searchable="true"
            />
            <div class="mt-5 self-center text-2xl font-bold">
              <template
                v-if="
                  model.separator?.id?.length > 1 &&
                  model.variableTwo !== 'NONE' &&
                  model.variableThree !== 'NONE'
                "
              >
                {{ model.separator?.id?.slice(-1) }}
              </template>
            </div>
          </div>
        </div>

        <div class="bg-gray-750 mt-8 w-full rounded-lg px-6 py-4">
          <p class="text-sm font-medium">Sample output</p>
          <p class="my-2">
            <span class="inline-flex">
              <span class="font-bold md:whitespace-pre">
                {{ referenceExample.preview }}
              </span>
            </span>
          </p>
          <p class="text-sm">
            In this example, the lease name includes the
            <Tooltip
              placement="top"
              class="bg-gray-950 text-xs"
              outer-class="font-medium underline decoration-dotted text-primary"
              content="First variable"
            >
              {{ options1.find((o) => o.id === model.variableOne)?.name }}
            </Tooltip>
            <template v-if="model.variableTwo && model.variableTwo !== 'NONE'">
              {{
                model.variableThree && model.variableThree !== 'NONE'
                  ? ','
                  : ', and the'
              }}
              <Tooltip
                placement="top"
                class="bg-gray-950 text-xs"
                outer-class="font-medium underline decoration-dotted text-primary"
                content="Second variable"
              >
                {{ options2.find((o) => o.id === model.variableTwo)?.name }}
              </Tooltip>

              <template
                v-if="model.variableThree && model.variableThree !== 'NONE'"
                >, and the
                <Tooltip
                  placement="top"
                  class="bg-gray-950 text-xs"
                  outer-class="font-medium underline decoration-dotted text-primary"
                  content="Third variable"
                >
                  {{ options3.find((o) => o.id === model.variableThree)?.name }}
                </Tooltip>
              </template> </template
            >. This format makes it simple to quickly spot and access the
            details you need.
          </p>
        </div>

        <div class="mt-4 flex justify-end">
          <Button
            type="submit"
            :loading="form?.node.context.state.loading"
            class="mr-2"
            @click.prevent="form?.node.submit()"
          >
            Save
          </Button>
        </div>
      </FormKit>
    </section>
  </div>
</template>
