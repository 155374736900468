export const sampleNamingConventionOutput: Record<string, string | string[]> = {
  AgreementDate: '01 Jun 2023',
  AirConditioningHours: '9:00am - 6:00pm',
  Size: '200',
  SizeUnit: 'sqm',
  BankGuaranteeCurrency: 'USD',
  BankGuarantee: '10000',
  BankGuaranteeCondition: 'Held until lease expiry',
  BaseRent: '50000',
  BaseRentFrequency: 'Annual',
  BaseRentPerSquareFootPerMonth: '2.5',
  BusinessCommencementDate: '01 Jul 2023',
  CashIncentiveAmount: '5000',
  CashIncentiveEndDate: '30 Jun 2024',
  CashIncentiveStartDate: '01 Jul 2023',
  CommencementDate: '01 Jun 2023',
  RentIncreaseCpiAdjustmentMethod: 'Annual',
  RentIncreaseCpiPercent: '2.5',
  CustomFields: 'Custom value',
  EarnestFeeAmount: '1000',
  ExpiryDate: '31 May 2028',
  FitoutIncentiveAmount: '15000',
  FitoutIncentiveEndDate: '31 Dec 2023',
  FitoutIncentiveStartDate: '01 Jun 2023',
  GreaterOrLesserRule: 'Greater',
  Guarantor: 'John Smith',
  GuarantorCondition: 'Personal guarantee for lease obligations',
  HandoverDate: '15 May 2023',
  LesseeAbn: '09876543210',
  LesseeAddress: '456 Market Road, Sydney',
  LesseeName: 'Fashion Hub',
  LesseePrincipalPlaceOfBusiness: 'Sydney',
  LesseeRegisteredAddress: '456 Market Road, Sydney',
  LesseeTradingName: 'Fashion Hub Sydney',
  LessorAbn: '12345678901',
  LessorAddress: '789 Corporate Avenue, Sydney',
  LessorName: 'John Doe Investments',
  MailBoxDepositAmount: '200',
  MarketReviewCap: '10%',
  MarketReviewCollar: '2%',
  MarketReviewDate: '01 Jun 2026',
  MarketingLevyAmount: '5000',
  MarketingLevyCondition: 'Annual review',
  MarketingLevyReviewCpiOptions: 'Annual',
  MarketingLevyPerSquareFootPerMonth: '2',
  MarketingLevyPercentage: '2.5%',
  MarketingLevyReviewCpiAdjustmentMethod: 'Annual',
  MarketingLevyReviewCpiCap: '5%',
  MarketingLevyReviewCpiCollar: '1%',
  MarketingLevyReviewCpiPercentage: '2%',
  MarketingLevyReviewAmount: '1000',
  MarketingLevyReviewPercentage: '2%',
  MarketingLevyReviewGreaterOf: '2%',
  MarketingLevyReviewLesserOf: '1%',
  MarketingLevyReviewTable: ['01 Jun 2024: 2%', '01 Jun 2026: 2%'],
  MarketingLevyReviewMethod: 'Fixed',
  MarketingLevyStartDate: '01 Jun 2023',
  OptionExerciseDetails: [
    'Option 1: Extend for 5 years',
    'Option 2: Terminate with 6 months notice',
  ],
  OptionExercisePeriod: '6 months before expiry',
  OptionInitialRentIncreaseCap: '10%',
  OptionInitialRentIncreaseCollar: '2%',
  OptionInitialRentIncreaseCpiPercent: '2%',
  OptionInitialRentIncreaseFixedPercent: '5%',
  OptionInitialRentIncreaseFormula:
    'Fixed increase or CPI, whichever is greater',
  OptionInitialRentIncreaseGreaterOf: 'Fixed',
  OptionInitialRentIncreaseLesserOf: 'CPI',
  OptionInitialRentIncreaseOptions: 'Annual fixed increase',
  OptionInitialRentIncreaseType: 'Fixed',
  OptionRentIncreaseTable: ['01 Jun 2024: 5%', '01 Jun 2026: 5%'],
  OptionTerm: '5 years',
  OptionToPurchase: 'No',
  OptionToRenew: 'Yes',
  OutgoingsCost: '10000',
  OutgoingsType: 'Proportionate',
  PaymentFrequency: 'Monthly',
  OutgoingsAgreedPercent: '10%',
  TurnoverPercentageRent: '7%',
  PermittedUse: 'Retail',
  ShopNumber: '102',
  PropertyAddress: '123 Main Street, Sydney',
  PropertyName: 'Central Plaza',
  PublicLiabilityInsuranceAmount: '20000000',
  RedecorationFrequency: 'Every 5 years',
  RegistrationNumber: '987654321',
  RenovationDepositAmount: '5000',
  RenovationManagementFeeAmount: '2000',
  RenovationPeriod: '3 months',
  RentIncentiveAmount: '10000',
  RentIncentiveEndDate: '31 Dec 2023',
  RentIncentiveMonths: '6',
  RentIncentiveStartDate: '01 Jun 2023',
  RentIncreaseCpiOptions: 'Annual',
  RentIncreaseFormula: 'Fixed or CPI, whichever is greater',
  RentIncreaseMarketOptions: 'Market review every 3 years',
  RentReviewCondition: 'Every 2 years',
  RentIncreaseCpiCap: '5%',
  RentIncreaseCpiCollar: '2%',
  RentIncreaseFixedAmount: '1500',
  RentIncreaseFixedPercent: '3%',
  RentIncreaseGreaterOf: 'Fixed',
  RentIncreaseLesserOf: 'CPI',
  RentIncreaseTable: ['01 Jun 2024: 3%', '01 Jun 2026: 3%'],
  RentIncreaseMethod: 'Fixed',
  RetailLeasingAct: 'Complies with Retail Leasing Act',
  SecurityDepositAmount: '5000',
  ServiceChargeAmount: '2000',
  ServiceChargePerSquareFootPerMonth: '1',
  ShopLevel: 'Ground',
  PrimaryUsage: 'Clothing Store',
  SecondaryUsage: 'Accessories',
  Term: '5 years',
  TradingHours: 'Monday to Friday from 9:00am to 5:00pm',
  UtilitiesDepositAmount: '1000',
  LeaseKind: 'Retail',
}
